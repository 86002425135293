<template>
    <div>

    </div>
</template>
<script>
    // Services
    import ConstantService from "@/services/ConstantService";

    export default {
        components: {

        },
        metaInfo() {
            return {
                title: this.$route.params.filename
            }
        },
        data() {
            return {

            }
        },
        created() {
            this.downloadFile()
        },
        methods: {
           downloadFile(){
               ConstantService.downloadPublicFile(this.$route.params.key).then(response => {
                   this._downloadFile(response, this.$route.params.filename)
               }).catch(e => {
                   this.showErrors(e)
               })
           }
        }
    }
</script>

